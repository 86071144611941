@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  5%,15% {
    transform: scale3d(1.25, 1.25, 1.25);
  }

  10%, 20%, 100% {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation: pulse 0.3s 1;
  -webkit-animation: pulse 0.4s 1;
}

.repeatingPulse {
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count:  infinite;  
  animation-delay: 2s;
}

